import UnknownPrototypeError from '@/errors/UnknownPrototypeError';

export default class SetAdaptationPrototype
{
    /**
     * Vue Object
     */
    v = null;

    /**
     * "right" or "left"
     * @string
     */
    side = null;

    constructor(v, side)
    {
        this.v = v;
        this.side = side;
    }

    async handle()
    {
        const lensOrderUrlManager = this.v.lensOrderUrlManager;

        // Ignore sides with no prototype
        if(!lensOrderUrlManager.hasPrototypeCode(this.side))
        {
            return true;
        }

        const adaptation = this.v.$store.state.lensOrder.adaptations[this.side];
        const prototypeCode = lensOrderUrlManager.getPrototypeCode(this.side);

        // If there is a prototype or if the prototype has changed => set adaptation prototype
        if(!adaptation.prototype || prototypeCode !== adaptation.prototype.code)
        {
            const prototypeAttributes = this.v.$store.getters['prototypes/getByCode'](prototypeCode);
            if(!prototypeAttributes)
            {
                throw new UnknownPrototypeError(prototypeCode);
            }

            adaptation.setPrototype(prototypeAttributes);
            this.v.sides[this.side].prototypeCode = prototypeCode;

            console.log(`Adaptation prototype has been set or updated for ${this.side} side.`, prototypeAttributes);
        }
        else
        {
            console.log(`Adaptation prototype for ${this.side} side is the same as in last dispatch`);
        }

        return true;
    }
}
