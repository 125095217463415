<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper">
            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <div class="sl-order-form__graph">
                    <div
                        class="lsn-btn rounded"
                        :tabindex="tabIndex"
                        @click.prevent="openGraph"
                        @keypress.enter="openGraph"
                    >
                        <mdi-icon icon="mdiChartBellCurve" class="inline-block w-5 h-5" />

                        <span class="inline-block ml-2">
                            {{ $t('parameters.PLOT_GRAPH_BUTTON.button_label') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Graph Window -->
        <teleport to="body">
            <lsn-modal :is-open="graphOpen" class="sl-graph" @click-outside="closeGraph">
                <div class="p-8 text-gray-500">
                    <!-- Chart Area -->
                    <div class="relative w-[1000px] h-[250px] resize overflow-hidden">
                        <canvas id="graph-area" ref="graph-area"></canvas>
                    </div>

                    <!-- Dia/Sag Table -->
                    <div v-if="$store.getters['account/isStaff']" class="mt-8 text-gray-600 text-sm">
                        <div class="grid" :class="zoneTableClass">
                            <!-- Rows 1 & 2: Zone Name & Dia/Sag Headings -->
                            <div class="row-span-2 border-r border-b">
                                <!-- empty -->
                            </div>

                            <div
                                v-for="zone in zones"
                                :key="zone"
                                class="col-span-2 flex justify-center items-center border-t border-r text-center"
                            >
                                <div class="mr-1.5 w-9 h-3" :style="`background-color: ${zoneColors[zone]};`"></div>

                                <div>
                                    {{ $t(`lens_zones.${zone}.label`) }}
                                </div>
                            </div>

                            <template v-for="zone in zones" :key="zone">
                                <div class="border-b text-center">
                                    ⌀
                                </div>

                                <div class="border-r border-b text-center">
                                    SAG
                                </div>
                            </template>

                            <template
                                v-for="(meridian, j) in backSurfacePoints.filter(m => !m.calculated)"
                                :key="j"
                            >
                                <!-- Rows 3+: Meridian Data -->
                                <div class="border-x border-b text-center">
                                    <span class="text-primary-500">{{ meridian.angle }}</span>°
                                </div>

                                <template v-for="zone in zones" :key="zone">
                                    <!-- Diameter -->
                                    <div class="border-r border-b text-center">
                                        <span class="text-primary-500">{{ getLastPoint(meridian, zone)[0].toFixed(1) }}</span>
                                        mm
                                    </div>

                                    <!-- Sagittal -->
                                    <div class="border-r border-b text-center">
                                        <span class="text-primary-500">{{ getLastPoint(meridian, zone)[1].toFixed(0) }}</span>
                                        µm
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>

                    <!-- Meridian Selector -->
                    <div class="mt-8">
                        <div class="flex justify-center items-center gap-4">
                            <button
                                v-for="a in [0, 90]"
                                :key="a"
                                class="lsn-btn rounded"
                                :class="isMoving
                                    ? ['lsn-btn--gray', 'lsn-btn--disabled']
                                    : 'lsn-btn--primary'"
                                :disabled="isMoving"
                                @click="moveToAngle(a)"
                            >
                                {{ a }}° &harr; {{ getOppositeAngle(a) }}°
                            </button>

                            <div
                                class="cursor-pointer flex justify-center items-center rounded border pl-0.5 pr-4 py-0.5 bg-white"
                                :class="isMoving
                                    ? ['border-gray-500', 'text-gray-500']
                                    : ['border-primary-500', 'text-primary-500']"
                                @click="$refs.angleInput.focus(); $refs.angleInput.select();"
                            >
                                <div>
                                    <input
                                        ref="angleInput"
                                        v-model="angle"
                                        type="number"
                                        min="0"
                                        max="359"
                                        step="1"
                                        class="rounded pl-2 py-0.5 w-[60px] text-center outline-none"
                                        :disabled="isMoving"
                                    >
                                </div>

                                <div class="mx-2 select-none">
                                    &harr;
                                </div>

                                <div class="select-none">
                                    {{ getOppositeAngle(angle) }}°
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </lsn-modal>
        </teleport>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { shallowRef } from 'vue';
import Chart from 'chart.js/auto';
import AbstractField from '../AbstractField.vue';
import axios from '@/axios';
import Numbers from '@/utils/Numbers';

const HUE_START = 192.5; // SL blue
const HUE_STEP = 360 / 6; // up to 6 colors
const SCALE_PADDING_X = 0.25;
const SCALE_PADDING_Y = 250;

export default
{
    name: 'PlotGraphButton',

    extends: AbstractField,

    setup()
    {
        const graph = null;
        const backSurfacePoints = shallowRef([]);

        return {
            graph,
            backSurfacePoints,
        };
    },

    data()
    {
        return {
            loadingPoints: false,
            graphOpen:     false,
            angle:         0,
            isMoving:      false,
        };
    },

    computed: {
        /**
         * The curve to be drawn in the graph area.
         */
        backSurface()
        {
            const angle = this.angle;
            const oppositeAngle = this.getOppositeAngle(angle);

            const rightMeridian = this.getMeridian(angle);
            const leftMeridian = this.getMeridian(oppositeAngle);
            if(rightMeridian === null || leftMeridian === null)
            {
                return {};
            }

            const values = {};
            for(const surfaceName in rightMeridian.points)
            {
                const positiveValues = rightMeridian.points[surfaceName]
                    .flatMap(segment => segment.map(([x, y]) => [x, -y]));
                const negativeValues = leftMeridian.points[surfaceName]
                    .flatMap(segment => segment.map(([x, y]) => [-x, -y]));

                values[surfaceName] = negativeValues.reverse()
                    .concat([[null, null]]) // add a gap
                    .concat(positiveValues);
            }

            return values;
        },

        graphScales()
        {
            // Approximative default values for when there's no data
            const scales = {
                x: {
                    min: -8,
                    max: +8,
                },
                y: {
                    min: -2500,
                    max: +2500,
                },
            };
            if(this.backSurfacePoints.length === 0)
            {
                return scales;
            }

            const allPoints = this.backSurfacePoints.flatMap(
                m => Object.keys(m.points).flatMap(
                    z => m.points[z].flat()
                )
            );
            const allXes = allPoints.map(([x]) => x);
            const allYs = allPoints.map(([, y]) => -y);

            scales.x.max = Numbers.roundToMultiple(Math.max(...allXes) + SCALE_PADDING_X, SCALE_PADDING_X);
            scales.x.min = -scales.x.max;
            scales.y.min = Numbers.roundToMultiple(Math.min(...allYs) - SCALE_PADDING_Y, SCALE_PADDING_Y);
            scales.y.max = 0 + SCALE_PADDING_Y;

            scales.x.ticks = {
                beginAtZero: true,
                stepSize:    2,
                precision:   1,
                callback(value, index, tick)
                {
                    return value === 0 ? '0' : value.toFixed(1);
                },
            };
            scales.y.ticks = {
                beginAtZero: true,
                stepSize:    SCALE_PADDING_Y,
                precision:   0,
                callback(value, index, tick)
                {
                    if(value > 0) return '';
                    return (-value).toFixed(0);
                },
            };

            return scales;
        },

        zones()
        {
            return Object.keys(this.backSurfacePoints[0]?.points ?? {});
        },

        zoneTableClass()
        {
            // Tailwind classes must be written in full for building
            return {
                'hidden':       this.zones.length < 2,
                'grid-cols-5':  this.zones.length === 2,
                'grid-cols-7':  this.zones.length === 3,
                'grid-cols-9':  this.zones.length === 4,
                'grid-cols-11': this.zones.length === 5,
            };
        },

        zoneColors()
        {
            const colors = {};
            let hue = HUE_START;
            for(const zoneName in this.backSurface)
            {
                colors[zoneName] = `hsl(${hue}, 85%, 50%)`;

                hue = (hue + HUE_STEP) % 360;
            }

            return colors;
        },
    },

    watch:
    {
        backSurface()
        {
            if(this.graph === null)
            {
                return;
            }

            // Rebuild datasets
            let hue = HUE_START;
            this.graph.data.datasets = [];
            for(const zoneName in this.backSurface)
            {
                this.graph.data.datasets.push({
                    label:           this.$t(`lens_zones.${zoneName}.label`),
                    borderColor:     this.zoneColors[zoneName],
                    backgroundColor: this.zoneColors[zoneName],
                    pointRadius:     0,
                    showLine:        true,
                    borderJoinStyle: 'bevel',
                    borderWidth:     1,
                    data:            this.backSurface[zoneName],
                });

                hue = (hue + HUE_STEP) % 360;
            }

            // Update the display
            this.graph.update();
        },
    },

    updated()
    {
        if(!this.graph && this.$refs['graph-area'])
        {
            this.angle = 0;
            this.initGraph(this.$refs['graph-area']);
        }
        else if(this.graph && !this.$refs['graph-area'])
        {
            // Properly dispose of the graph
            this.graph.destroy();
            this.graph = null;
        }
    },

    methods:
    {
        openGraph()
        {
            // Open the chart window
            this.graphOpen = true;

            if(this.loadingPoints)
            {
                return;
            }

            const cPrototype = this.$store.getters['lensOrder/getPrototype'](this.side);

            // Load up the points
            this.loadingPoints = true;
            const url = '/api/prototype/calculate-points/:eid/:prototypeCode/:surfaceName'
                .replace(':eid', this.$store.state.account.cEntity.id)
                .replace(':prototypeCode', cPrototype.code)
                .replace(':surfaceName', 'back');
            const data = {
                values: cPrototype.getValues(),
                options:
                {
                    resolution: 0.01,
                    y_offset:   0.0,
                },
            };
            axios.post(url, data)
                .then(({ data: meridians }) =>
                {
                    // Display adjustments:
                    // Diameters: Double all X values
                    // Sagittals: 1000x all Y values
                    for(const j in meridians)
                    {
                        for(const zoneName in meridians[j].points)
                        {
                            for(const s in meridians[j].points[zoneName])
                            {
                                for(const i in meridians[j].points[zoneName][s])
                                {
                                    meridians[j].points[zoneName][s][i][0] *= 2;
                                    meridians[j].points[zoneName][s][i][1] *= 1000;
                                }
                            }
                        }
                    }

                    const out = meridians.slice();

                    // Generate opposite angles to make full-width meridians
                    for(const meridian of meridians)
                    {
                        const oppositeAngle = this.getOppositeAngle(meridian.angle);
                        const oppositeMeridianIndex = out.findIndex(m => m.angle === oppositeAngle);
                        if(oppositeMeridianIndex !== -1)
                        {
                            // The opposite meridian exists; don't recaclulate.
                            continue;
                        }

                        // The opposite meridian doesn't exist;
                        // mirror its X values, but keep the Y values.
                        const oppositeMeridian = {
                            angle:      oppositeAngle,
                            points:     meridian.points,
                            calculated: true,
                        };

                        for(const zoneName in oppositeMeridian.points)
                        {
                            const zone = oppositeMeridian.points[zoneName];
                            for(let s = 0; s < zone.length; s++) // "s" is for "segment"
                            {
                                const segment = zone[s];
                                for(let i = 0; i < segment.length; i++)
                                {
                                    segment[i].x = -segment[i].x;
                                }
                            }
                        }

                        const meridianIndex = out.findIndex(m => m.angle === meridian.angle);
                        out.splice(meridianIndex + 1, 0, oppositeMeridian);
                    }

                    this.backSurfacePoints = out;
                    console.log('--- backSurfacePoints', this.backSurfacePoints); // @XXX
                    this.$nextTick(() =>
                    {
                        this.graph.options.scales = this.graphScales;
                        this.graph.update();
                    });
                })
                .catch(error =>
                {
                    // todo: handle error
                })
                .then(() =>
                {
                    this.loadingPoints = false;
                });
        },

        closeGraph()
        {
            this.graphOpen = false;
        },

        initGraph(ctx)
        {
            // Initialize the graph instance
            if(!ctx)
            {
                console.warn('Graph area is unavailable!');
                return;
            }

            const graphOptions = {
                type:    'scatter',
                options: {
                    animation:           false,
                    responsive:          true,
                    maintainAspectRatio: false,
                },
                data:
                {
                    datasets: [],
                },
            };
            this.graph = new Chart(ctx, graphOptions);
        },

        getOppositeAngle(angle)
        {
            return (angle + 180) % 360;
        },

        getMeridian(angle)
        {
            if(angle % 1 !== 0)
            {
                throw new TypeError("angle must be an integer");
            }

            const meridianIndex = this.backSurfacePoints.findIndex(m => m.angle === angle);
            if(meridianIndex !== -1)
            {
                // The meridian exists;
                // just return it.
                return this.backSurfacePoints[meridianIndex];
            }

            // The meridian doesn't exist;
            // find the two closest angles and interpolate the values
            // of each of their points.
            const prev = this.getPreviousMeridian(angle);
            const next = this.getNextMeridian(angle);
            if(prev === null || next === null)
            {
                return null;
            }

            const deg2rad = deg => deg * (Math.PI / 180);
            const alpha = deg2rad(Math.abs(angle - prev.angle));
            const meridian = {
                angle,
                points: {},
            };

            for(const zoneName in prev.points)
            {
                const prevZone = prev.points[zoneName];
                const nextZone = next.points[zoneName];

                meridian.points[zoneName] = [];
                for(let s = 0; s < prevZone.length; s++) // "s" is for "segment"
                {
                    const prevPoints = prevZone[s];
                    const nextPoints = nextZone[s];

                    const segment = [];
                    for(let i = 0; i < prevPoints.length; i++)
                    {
                        // px: diameter at previous angle
                        // py: sagittal at previous angle
                        // nx: diameter at next angle
                        // ny: sagittal at next angle
                        const [px, py] = prevPoints[i];
                        const [nx, ny] = nextPoints[i];
                        if(px !== nx)
                        {
                            throw new Error(`Previous and next diameters should be the same! prev=(dia=${px}, sag=${py}), next=(dia=${nx}, sag=${ny})`);
                        }

                        segment.push([
                            px,
                            py * (Math.cos(alpha) ** 2) + ny * (Math.sin(alpha) ** 2),
                        ]);
                    }

                    meridian.points[zoneName].push(segment);
                }
            }

            return meridian;
        },

        getPreviousMeridian(angle)
        {
            if(this.backSurfacePoints.length === 0)
            {
                return null;
            }

            angle %= 360;
            let currentAngle = angle;
            if(currentAngle < 0)
            {
                currentAngle += 360;
            }

            for(;;)
            {
                const meridian = this.backSurfacePoints.find(m => m.angle === currentAngle);
                if(meridian)
                {
                    return meridian;
                }

                currentAngle -= 1;
                if(currentAngle < 0)
                {
                    currentAngle += 360;
                }
            }
        },

        getNextMeridian(angle)
        {
            if(this.backSurfacePoints.length === 0)
            {
                return null;
            }

            angle %= 360;
            let currentAngle = angle;
            if(currentAngle < 0)
            {
                currentAngle += 360;
            }

            for(;;)
            {
                const meridian = this.backSurfacePoints.find(m => m.angle === currentAngle);
                if(meridian)
                {
                    return meridian;
                }

                currentAngle += 1;
                if(currentAngle >= 360)
                {
                    currentAngle -= 360;
                }
            }
        },

        setGraphScale(maxX, maxY, update = false)
        {
            this.graph.options.scales = {
                x: {
                    min: -Math.abs(maxX) - SCALE_PADDING,
                    max: +Math.abs(maxX) + SCALE_PADDING,
                },
                y: {
                    min: -Math.abs(maxY) - SCALE_PADDING,
                    max: 0 + SCALE_PADDING,
                },
            };

            if(update)
            {
                this.graph.update();
            }
        },

        /**
         * Smoothly change the viewing angle.
         *
         * @param {Number} targetAngle The target angle in degrees (integer).
         * @param {Number} duration The duration, in milliseconds (integer).
         */
        async moveToAngle(targetAngle, duration = 500)
        {
            if(this.isMoving)
            {
                return;
            }

            targetAngle = (360 + (Math.round(targetAngle) % 360)) % 360;
            if(targetAngle === this.angle)
            {
                return;
            }

            duration = Math.trunc(Math.abs(duration));
            if(duration <= 0)
            {
                return;
            }

            this.isMoving = true;

            const diff = targetAngle - this.angle;
            const stepMs = 33; // ~30fps
            const stepAngle = Math.round(diff / (duration / stepMs)) || Math.sign(diff);

            const t0 = Date.now();
            while(this.angle !== targetAngle)
            {
                await new Promise(resolve =>
                {
                    setTimeout(resolve, stepMs);
                });

                this.angle += stepAngle;
                if(
                    (diff > 0) && (this.angle >= targetAngle) ||
                    (diff < 0) && (this.angle <= targetAngle)
                )
                {
                    this.angle = targetAngle;
                    this.isMoving = false;
                }
            }
        },

        getLastPoint(meridian, zone)
        {
            return meridian.points[zone].at(-1).at(-1);
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__graph .lsn-btn
{
    @apply border-emerald-600 bg-emerald-600 text-white
        hover:bg-emerald-500 hover:border-emerald-500
        focus-visible:bg-emerald-500 focus-visible:border-emerald-500
        active:bg-emerald-700 active:border-emerald-700
}
</style>
